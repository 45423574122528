.form > input, .form > textarea
{
    width: 95%;
    background-color: grey;
    color: #009ED0;
    border: 0;
    padding: 7px 10px;
    margin: 4px 0px;
}

.form > input::placeholder, .form > textarea::placeholder
{
    color: #75c7e5;
    opacity: 1;
    font: normal normal normal 13px/1.2em 'Open Sans',sans-serif; line-height: normal;
}

.form > input::-ms-input-placeholder, .form > textarea::-ms-input-placeholder
{
    color: #75c7e5;
    font: normal normal normal 13px/1.2em 'Open Sans',sans-serif; line-height: normal;
}