.construction-wrapper {
    width: 100%;
    border-top: 1px solid #5fa7c2;
    border-bottom: 1px solid #5fa7c2;
}

.construction-wrapper *
{
    color: #00435c;
    width: fit-content;
    margin: 50px auto 50px;
    font-family: 'Noticia Text', serif;
    font-weight: bold;
}

.construction-wrapper a
{
    color: #0083b3 !important;
}

.construction-wrapper a:hover 
{
    color: #00afe4 !important;
}