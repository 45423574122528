.search-bar
{
    width: 65%;
    margin: auto;
    padding-bottom: 30px;
}

#search-form > input
{
    border: 0;
    margin: 0;
    padding: 7px;
}

#search-form > button
{
    padding: 7px 10px;
    margin-left: 10px;
    border: 0;
    background-color: white;
    border-radius: 10px;
    cursor: pointer;
}