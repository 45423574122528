.wrapper {
    max-width: 90%;
    margin: auto;
    padding: 0px 0px;
}

#container 
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content;
}

#container > div 
{ 
    margin: 20px;
    max-width: 40%;
    height: fit-content;
}

#profile
{
    border-radius: 50%;
    max-width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    height: auto;
}

#intro-text > p
{
    width: 100%;
}

.noticia-font
{
    font-family: 'Noticia Text', serif;
    color: #006990;
}
.font_3 { font-size: calc(100% + 3.4vw); margin: 0; }
.font_4 { font-size: calc(100% + 2vw); margin: 0; font-weight: normal; }

.link-circles
{
    display: flex;
    justify-content: letft;
    flex-wrap: wrap;
    margin: 10px 0px;
}

.circle
{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;

    margin: 10px 20px;

    min-width: 100px;
    min-height: 100px;

    height: 10vw;
    width: 10vw;
    /* max-height: 50vw;
    max-width: 50vw; */
    max-height: 130px;
    max-width: 130px;

    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.circle a, a:visited
{
    color: inherit;
    text-decoration: none;
    font-size: 13px;
}

.grey-link { background-color: #747575; }
.grey-link:hover { background-color: #525252 !important; }

.green-link { background-color: #79af13; }
.green-link:hover { background-color: #5a820e !important;}

.red-link { background-color: #e21c21; }
.red-link:hover { background-color: #af1519 !important; }
