@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noticia+Text:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

html {
	background-color: #FCDCAD;
}

body {
	min-height:100%;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Open Sans', 'Noticia Text'
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.no-margin { margin: 0; }
.font-bold { font-size: bold }

.font-1 { font: normal normal normal 13px/1.2em 'Open Sans',sans-serif; line-height: normal;}
.font-2 { font: normal normal normal 16px/1.2em 'Open Sans',sans-serif; line-height: normal;}
.font-3 { font: normal normal normal 17px/1.2em 'noticia text',serif; line-height: normal;}
.font-4 { font: normal normal normal 19px/1.2em 'noticia text',serif; }
.font-5 { font: normal normal normal 80px/1.2em 'noticia text',serif; }
