#cv-wrapper
{
    display: flex;
    height: fit-content;
    width: 1000px;
    max-width: 100%;
    margin: auto;
    padding: 0px 0px;
    color: #006990;
}

#main-panel
{
    float: left;
    width: 78%;
    height: fit-content;
    padding: 0px 50px 0px 75px;
}

#side-panel
{
    float:right;
    width: 22%;
    height: fit-content;
    padding: 100px 100px 0px 0px;

}

#main-panel h2
{
    font: normal normal normal 80px/1.2em 'noticia text',serif;
    margin: 0;
    padding-bottom: 28px;
    margin-bottom: 25px;
    line-height: normal;
    letter-spacing: normal;
    border-bottom: 1px solid #5fa7c2;
}

#side-panel h6
{
    font: normal normal normal 17px 'Noticia Text',serif;
    margin: 0 0 20px 0;
    padding: 0px 0px 10px 0px;
    border-bottom: 1px solid #5fa7c2;
}

#side-panel p, #side-panel li, #side-panel ul, .body-listing ul, .body-listing li, .body-listing p
{
    font: normal normal normal 13px/1.2em 'Open Sans',sans-serif; 
    line-height: normal;
    margin: 0;
}

.body-listing ul, .body-listing li, .listing-text
{
    line-height: 1.85em !important;
}

#side-panel .sub-text
{
    font: normal normal normal 10px/1.2em 'Open Sans',sans-serif;
    margin: 0;
    padding-top: 5px;
    padding-bottom: 15px; 
}

.icon
{
    display: flex;
    width: 45px;
    height: 45px;
    margin-bottom: 15px;
    background-color: #79af13;
    color: #fcdcad;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.icon .pic
{
    width: 75%;
    height: 75%;
}
