@import url(https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noticia+Text:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap);
html {
	background-color: #FCDCAD;
}

body {
	min-height:100%;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Open Sans', 'Noticia Text'
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.no-margin { margin: 0; }
.font-bold { font-size: bold }

.font-1 { font: normal normal normal 13px/1.2em 'Open Sans',sans-serif; line-height: normal;}
.font-2 { font: normal normal normal 16px/1.2em 'Open Sans',sans-serif; line-height: normal;}
.font-3 { font: normal normal normal 17px/1.2em 'noticia text',serif; line-height: normal;}
.font-4 { font: normal normal normal 19px/1.2em 'noticia text',serif; }
.font-5 { font: normal normal normal 80px/1.2em 'noticia text',serif; }

#main {
  background-color: #fcdcad;
  min-height: 100vh;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-width: 100%;
}

#page {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 175px;
}

#header
{
    width: 60%;
    margin: auto;
    color: #006990;
    padding: 2vh 0px 8vh;
}

#name-title
{
    font-family: 'Noticia Text', serif;
    font-weight: bold;
}

#name-title h1
{
    font-size: 43px;
    margin: 0px;
}

#name-title p
{
    font-size: 18px;
    margin: 0px;
}

.link, .link:visited
{
    font-family: 'Noticia Text', serif;
    font-weight: bold;
    margin: 0px 20px;
    color: #009ed0;
    text-decoration: none;
}

.link:hover
{
    color: #79af45;
}

.current
{
    color: #79af45 !important;
}

#footer
{
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 6vw;
    min-height: 100px;
    background-color: #2b6ca3;
    padding-bottom: 13px;
    color: white;
}

#bottom
{
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 10%;
    max-height: 13px;
    background-color: #002e5d;
}

.footer-contact-container
{
    display: -webkit-flex;
    display: flex;
    height: 100%;
    min-height: 100%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    max-width: 1040px;
    margin: auto;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.icon-box
{
    display: -webkit-flex;
    display: flex;
    background-color: #79af13;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-self: center;
            align-self: center;
    padding: 10px;
    border-radius: 50%;
    color: #2b6ca3;

    width: 3vw;
    height: 3vw;

    max-width: 35px;
    max-height: 35px;

    min-width: 15px;
    min-height: 15px;
}

@media only screen and (max-width: 1040px)
{
    .icon-box
    {
        display: none;
    }

    .footer-contact-container
    {
        -webkit-justify-content: space-evenly;
                justify-content: space-evenly;
    }
}

.contact-box
{
    display: -webkit-flex;
    display: flex;
    width: 240px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    padding: 0px 10px;
    -webkit-justify-content: center;
            justify-content: center;
}

@media only screen and (max-width: 780px)
{
    .contact-box
    {
        -webkit-justify-content: flex-start !important;
                justify-content: flex-start !important;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media only screen and (max-width: 540px)
{
    #footer
    {
        display: none;
    }
}

.contact-box-content > p
{
    margin: 0;
}

.contact-box-content > .social-icon
{
    padding: 0;
    margin: 3px;
}
.construction-wrapper {
    width: 100%;
    border-top: 1px solid #5fa7c2;
    border-bottom: 1px solid #5fa7c2;
}

.construction-wrapper *
{
    color: #00435c;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 50px auto 50px;
    font-family: 'Noticia Text', serif;
    font-weight: bold;
}

.construction-wrapper a
{
    color: #0083b3 !important;
}

.construction-wrapper a:hover 
{
    color: #00afe4 !important;
}
.wrapper {
    max-width: 90%;
    margin: auto;
    padding: 0px 0px;
}

#container 
{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

#container > div 
{ 
    margin: 20px;
    max-width: 40%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

#profile
{
    border-radius: 50%;
    max-width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    height: auto;
}

#intro-text > p
{
    width: 100%;
}

.noticia-font
{
    font-family: 'Noticia Text', serif;
    color: #006990;
}
.font_3 { font-size: calc(100% + 3.4vw); margin: 0; }
.font_4 { font-size: calc(100% + 2vw); margin: 0; font-weight: normal; }

.link-circles
{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: letft;
            justify-content: letft;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 10px 0px;
}

.circle
{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 50%;
    color: white;

    margin: 10px 20px;

    min-width: 100px;
    min-height: 100px;

    height: 10vw;
    width: 10vw;
    /* max-height: 50vw;
    max-width: 50vw; */
    max-height: 130px;
    max-width: 130px;
    transition: all .2s ease-in;
}

.circle a, a:visited
{
    color: inherit;
    text-decoration: none;
    font-size: 13px;
}

.grey-link { background-color: #747575; }
.grey-link:hover { background-color: #525252 !important; }

.green-link { background-color: #79af13; }
.green-link:hover { background-color: #5a820e !important;}

.red-link { background-color: #e21c21; }
.red-link:hover { background-color: #af1519 !important; }

#cv-wrapper
{
    display: -webkit-flex;
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 1000px;
    max-width: 100%;
    margin: auto;
    padding: 0px 0px;
    color: #006990;
}

#main-panel
{
    float: left;
    width: 78%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0px 50px 0px 75px;
}

#side-panel
{
    float:right;
    width: 22%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 100px 100px 0px 0px;

}

#main-panel h2
{
    font: normal normal normal 80px/1.2em 'noticia text',serif;
    margin: 0;
    padding-bottom: 28px;
    margin-bottom: 25px;
    line-height: normal;
    letter-spacing: normal;
    border-bottom: 1px solid #5fa7c2;
}

#side-panel h6
{
    font: normal normal normal 17px 'Noticia Text',serif;
    margin: 0 0 20px 0;
    padding: 0px 0px 10px 0px;
    border-bottom: 1px solid #5fa7c2;
}

#side-panel p, #side-panel li, #side-panel ul, .body-listing ul, .body-listing li, .body-listing p
{
    font: normal normal normal 13px/1.2em 'Open Sans',sans-serif; 
    line-height: normal;
    margin: 0;
}

.body-listing ul, .body-listing li, .listing-text
{
    line-height: 1.85em !important;
}

#side-panel .sub-text
{
    font: normal normal normal 10px/1.2em 'Open Sans',sans-serif;
    margin: 0;
    padding-top: 5px;
    padding-bottom: 15px; 
}

.icon
{
    display: -webkit-flex;
    display: flex;
    width: 45px;
    height: 45px;
    margin-bottom: 15px;
    background-color: #79af13;
    color: #fcdcad;
    border-radius: 50%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.icon .pic
{
    width: 75%;
    height: 75%;
}

.main-section 
{
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid #5fa7c2;
    margin-bottom: 25px;
}

.main-section .title-section
{
    display: -webkit-flex;
    display: flex;
    width: 120px;
    min-width: 120px;
    height: 120px;
    background-color: #747575;
    border-radius: 50%;
    color: white;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.body-section
{
    margin: 0;
    padding-left: 20px;
    width: 430px;
}

.title-section .text
{
    width: 105px;
}

.title-section .text h6
{
    font: normal normal normal 17px 'Noticia Text',serif;
    margin: 0;
}

.bottom
{
    padding: 0;
    margin: 0;
    border-bottom: none;
}

.search-bar
{
    width: 65%;
    margin: auto;
    padding-bottom: 30px;
}

#search-form > input
{
    border: 0;
    margin: 0;
    padding: 7px;
}

#search-form > button
{
    padding: 7px 10px;
    margin-left: 10px;
    border: 0;
    background-color: white;
    border-radius: 10px;
    cursor: pointer;
}

.contact-wrapper
{
    display: -webkit-flex;
    display: flex;
    width: 50%;
    max-width: 80%;
    margin: auto;
    padding: 0px 0px;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    color: #006990;
}

.info
{
    padding-right: 80px;
}

.contact
{
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    margin: 0;
    padding-bottom: 30px;
}

.social-icon-lg
{
    margin: 0px 5px;
}
.form > input, .form > textarea
{
    width: 95%;
    background-color: grey;
    color: #009ED0;
    border: 0;
    padding: 7px 10px;
    margin: 4px 0px;
}

.form > input::-webkit-input-placeholder, .form > textarea::-webkit-input-placeholder
{
    color: #75c7e5;
    opacity: 1;
    font: normal normal normal 13px/1.2em 'Open Sans',sans-serif; line-height: normal;
}

.form > input:-ms-input-placeholder, .form > textarea:-ms-input-placeholder
{
    color: #75c7e5;
    opacity: 1;
    font: normal normal normal 13px/1.2em 'Open Sans',sans-serif; line-height: normal;
}

.form > input::placeholder, .form > textarea::placeholder
{
    color: #75c7e5;
    opacity: 1;
    font: normal normal normal 13px/1.2em 'Open Sans',sans-serif; line-height: normal;
}

.form > input::-ms-input-placeholder, .form > textarea::-ms-input-placeholder
{
    color: #75c7e5;
    font: normal normal normal 13px/1.2em 'Open Sans',sans-serif; line-height: normal;
}
