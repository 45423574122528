#main {
  background-color: #fcdcad;
  min-height: 100vh;
  height: fit-content;
  min-width: 100%;
}

#page {
  height: fit-content;
  padding-bottom: 175px;
}
